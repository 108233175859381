<template>
  <v-container>
    <Patcher
      :itemValue="itemData.mediaUuid"
      itemName="mediaUuid"
      itemLabel="Foto"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement=""
      inputType="media"
      :previewValue="itemData.mediaUuid"
      @emit="itemData.mediaUuid = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.name"
      itemName="name"
      itemLabel="Name"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.name"
      @emit="itemData.name = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.description"
      itemName="description"
      itemLabel="Beschreibung"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="textarea-md"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.description"
      @emit="itemData.description = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.emailInfo"
      itemName="emailInfo"
      itemLabel="Text in E-Mails"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="textarea-md"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      hint="Verfügbare Platzhalter: %campaignscoutingBonusEndDate%"
      :previewValue="itemData.emailInfo"
      @emit="itemData.emailInfo = $event.value"
    ></Patcher>
  </v-container>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "Testimonial",

  components: {
    Patcher,
  },

  props: ["itemData"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: "scouting-boni/",
      fetchingData: false,
    };
  },

  mixins: [apiRequest, helpers],
};
</script>
